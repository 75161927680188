

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

body {
  font-family: "Open Sans", sans-serif;
}

.container {
  @apply m-auto max-w-screen-xl p-4;
}

.take-action-page-red {
  background: #b71d24;
}

.take-action-page {
  position: relative;
  height: 100vh;
}

.mobile-image-inherit {
  height: 80%;
  position: absolute;
  bottom: 0;
}

.red-nav-bg {
  /* background: #b71d24; */
  background: white;
  height: 8rem;
}

.trans-nav-bg-fixed {
  position: fixed !important;
}

.no-red-nav-bg {
  position: fixed !important;
  background: transparent !important;
  /* background: white; */
}

.breaking-position {
  position: absolute;
  top: 37%;
  right: 10rem;
}

.manifesto-bg {
  background-color: #000000de;
}

.page-text-alt {
  color: #000 !important;
}

.page-text {
  font-size: 35px;
  color: #fff;
  font-weight: 600;
}

.manifesto-p {
  font-weight: 400;
  font-size: 24px;
  color: #fff;
}

.policy-header {
  font-size: 6rem;
  color: #fff;
  position: absolute;
  font-weight: 600;
  bottom: 5rem;
  left: 5rem;
}

.policy-header-sub {
  font-size: 2rem;
  color: #fff;
  position: absolute;
  font-weight: 600;
  bottom: 2rem;
  left: 16rem;
}

.manifesto-p-alt {
  color: #000;
}

.nav-bg {
  position: fixed;
  background-color: #fff;
  top: 0;
  z-index: 13;
  width: 100%;
}

.the-idea-text {
  color: #fff;
  font-weight: 900;
  font-size: 24px;
}


.highlight {
  transform: scale(1.2);
  transition: transform 0.3s;
}

.blood-red {
  background-color: #b92026 !important;
}

.green-color {
  background-color: #009844 !important;
}

.white-color {
  background-color: #fff !important;
  color: #000 !important;
}

.red-color {
  color: #ec1c24;
}

.black-color {
  color: black;
  text-shadow: 2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff,
    -1px -1px #fff, 1px -1px #fff, -1px 1px #fff;
}

.gold-color {
  background-color: #b5a232 !important;
}

.policy-banner-img {
  border-radius: 15px;
  width: 80%;
}

.active-nav-text {
  border-bottom: solid 1px black;
}

.alt-nav-text {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding-bottom: 0px;
  padding-left: 20px;
}

.alt-nav-text::after {
  content: "";
  position: absolute;
  border-radius: 15px;
  width: 45%;
  height: 3px;
  bottom: 5px;
  left: 20%; /* Center the underline */
  background-color: #009844;
  transform-origin: center;
}

.ug-speech-img {
  margin-left: 10rem;
  width: 35rem;
  height: 70vh;
}

.ug-speech-bg {
  background-color: #ebf8f8;
}

.breaking-img-width {
  width: 40rem;
}

.text-lg {
  font-size: 1rem !important;
  line-height: 1.75rem !important;
}

.font-normal {
  font-weight: 600 !important;
}

.nav-text {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding-bottom: 0px;
  padding-left: 20px;
}

.nav-text::after {
  content: "";
  position: absolute;
  border-radius: 15px;
  width: 45%;
  height: 3px;
  bottom: 5px;
  left: 20%; /* Center the underline */
  background-color: #b91f26;
  transform-origin: center;
}

.take-p {
  font-weight: 100;
  font-size: 16px;
  text-align: end;
  width: 20rem;
}

.take-p-color {
  color: #fff;
}

.take-p-alt {
  text-align: start !important;
}

/* .home-p {
  font-weight: 500;
  font-size: 20px;
} */

.take-p-footer {
  color: #b71d24;
  font-size: 14px;
  font-weight: 400;
}

.take-action-p {
  margin-top: 1rem;
  font-weight: 900;
  font-size: 44px;
  color: #b91f26;
}

.home-p-alt {
  font-weight: 400;
  font-size: 24px;
  color: #b91f26;
}

.header-text-sub-alt {
  text-align: end !important;
}

.header-text-sub-alt-2 {
  text-align: start !important;
}

.header-text-sub-green {
  color: #009844 !important;
}

.img-opacity {
  opacity: 0.4;
}

.header-text-sub-alt-size {
  font-size: 2.3rem !important;
}

.header-text-sub {
  color: #b91f26;
  font-size: 24px;
  font-weight: 600;
  width: 100%;
  text-align: justify;
  line-height: 30px;
}

/* .header-text {
  font-size: 2.5rem;
  font-weight: 900;
} */

.header-text-sub-mobile {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  text-align: justify;
  line-height: 24px;
  margin-bottom: 1rem;
}

.header-text-mobile {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 1rem;
}

.socials-position {
  position: absolute;
  left: 10rem;
  bottom: 2rem;
}

.icon-color {
  color: #fff;
}

.text-position {
  position: absolute;
  top: 12rem;
  width: 40%;
  left: 10rem;
  z-index: 9;
}

.button-primary-alt {
  background-color: #009844 !important;
}

.bio-header {
  font-size: 48px;
  color: #fff;
  font-weight: 600;
}

.bio-header-sub {
  font-size: 32px;
  color: #009844;
  font-weight: 600;
}

.contact-p-sub {
  color: #fff;
}

/* .contact-header {
  font-size: 24px;
  color: #fff;
  font-weight: 400;
  text-transform: uppercase;
} */

.red-footer-bg {
  background: #b71d24;
}

.red-footer-bg-alt {
  height: 2rem;
  background-color: #710b10;
}

.bio-p {
  margin-top: 2rem;
  color: #fff;
  font-size: 18px;
  text-align: justify;
  width: 90%;
  font-weight: 100;
}

.button-primary-text {
  font-size: 24px !important;
  padding: 15px 35px !important;
}

.button-primary {
  width: fit-content;
  margin-right: 0px;
  margin-left: 0px;
  padding: 15px 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #b91f26;
  color: #fff;
  font-size: 16px;
  line-height: 17.41px;
  font-weight: 900;
  text-transform: none;
  z-index: 9;
}

.button-green-primary {
  margin-right: 0px;
  margin-left: 0px;
  padding: 15px 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #009844;
  width: fit-content;
  color: #fff;
  font-size: 22px;
  line-height: 10px;
  font-weight: 600;
  text-transform: none;
  z-index: 9;
}

.home-h {
  height: 100%;
}

.no-scroll {
  overflow-x: hidden;
}

.home-banner-alt {
  position: absolute;
  top: 17%;
  left: 8%;
  z-index: 1;
}

.take-action-page-padding-alt {
  padding-left: 2.5rem;
}

.take-action-page-padding {
  padding-right: 2.5rem;
}

.vote-for-ug {
  position: absolute;
  bottom: 2%;
  right: 20%;
  width: 50%;
  z-index: 12;
}

.take-banner-alt {
  position: absolute;
  top: 90px;
  left: 350px;
}

.hero-width {
  width: 30%;
}

.quote-bg {
  width: 100%;
  height: 30rem;
  background-color: #b92026;
}

.promise-bg {
  width: 100%;
  height: 100%;
  background-color: #009845;
}

.pledge-bg {
  /* padding: 5rem; */
  width: 100%;
  /* height: 100%; */
  background-image: url("./assets/G1.png");
  background-position: 100% 100%;
  background-size: cover;
  background-position: top left;
  background-repeat: no-repeat;
}

.vision-bg {
  /* padding: 5rem; */
  width: 100%;
  /* height: 100%; */
  background-image: url("./assets/G2.png");
  background-position: 100% 100%;
  background-position: top left;
  background-size: cover;
  background-repeat: no-repeat;
}

.my-promise-header {
  margin: 5rem 5rem;
  font-size: 45px;
  color: white;
  font-weight: 900;
  line-height: 10px;
}

.my-pledge-header {
  margin: 3rem 5rem;
  font-size: 45px;
  color: white;
  font-weight: 900;
  line-height: 10px;
}

.my-quote-header-name {
  color: #fff;
  font-weight: 500;
  font-size: 24px;
  margin-top: 1rem;
}

.my-quote-header-sub {
  margin: 0rem 5rem;
  font-size: 30px;
  color: white;
  font-weight: 400;
  line-height: 45px;
}

.on-cotransitintainer {
  position: relative;
}

.section {
  display: none;
  position: absolute;
  width: 100%;
  height: 100vh;
  opacity: 0;
  transition: opacity 1s;
}

.section.active {
  display: flex;
  opacity: 1;
}

.my-promise-header-sub {
  margin: 0rem 5rem;
  font-size: 22px;
  color: white;
  font-weight: 400;
  line-height: 45px;
  width: 70%;
}

.my-pledge-header-sub {
  /* margin: 5rem 5rem; */
  /* font-size: 25px; */
  color: white;
  font-weight: 400;
  line-height: 45px;
  width: 70%;
  /* height: fit-content; */
}

.overlay-alt-sub {
  background: transparent !important;
}

.overlay-alt {
  border-radius: 15px;
  padding: 0rem;
  height: max-content;
  z-index: 1;
  width: 50%;
  background: #ffffff42;
  /* overflow-y: auto; */
}

.home-banner-alt-n {
  height: 400px !important;
  width: -moz-available !important; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available !important; /* Mozilla-based browsers will ignore this. */
  width: stretch !important;
  background-position: center bottom !important;
}

.home-banner {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 500px;
  background-image: url("./assets/backgroud.png");
  background-position: 100% 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.home-banner-user {
  position: absolute;
  bottom: 0;
  right: 0;
}

.custom-user-height {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
  z-index: 12;
}

.hideme {
  opacity: 0;
}

.hideme div {
  margin: 50px;
  padding: 50px;
  background-color: lightgreen;
}

.about-body-bg {
  background: #f2f2f2;
}

.alt-body-bg {
  background: #fff;
}

.vote-bg-mobile {
  top: -50px;
  z-index: 99;
}

.mobile-card-bg {
  background: #b71d24;
  padding: 2rem 2rem;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  height: 100%;
}

.mobile-nav-text-normal {
  /* position: relative; */
  color: #000;
  text-align: center;
  margin-bottom: 1rem;
}

.mobile-nav-text {
  /* position: relative; */
  color: #000;
  text-align: center;
  margin-bottom: 1rem;
}

.mobile-nav-text::after {
  content: "";
  position: absolute;
  border-radius: 15px;
  width: 15%;
  height: 3px;
  bottom: 5px;
  left: 40%;
  /* background-color: #b91f26; */
  transform-origin: center;
}

/* .mobile-nav-bg {
  padding: 1rem;
  background: #fff;
  border-radius: 15px 15px 15px 15px;
  top: 5rem;
  right: 0.5rem;
  position: absolute;
  width: 60%;
  color: white;
  z-index: 9;
  box-shadow: 0px 13px 20px 0px rgb(15 15 15 / 78%);
} */

.modal {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  border-radius: 5px;
  position: fixed;
  width: 55%;
  height: auto;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-header {
  display: flex;
  align-items: end;
  justify-content: end;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.content-media-frame {
  width: 100%;
  height: 600px;
  border: 0;
}

.bio-img-1 {
  width: 85%;
  height: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffffe8; /* Adjust the color and opacity as needed */
  z-index: 1;
}

.manifesto-header {
  font-weight: 700;
  font-size: 35px;
  margin-top: 1rem;
  color: #b71d24;
  margin-bottom: 1rem;
  line-height: 46px;
  text-wrap: wrap;
}

.manifesto-header-small {
  font-size: 25px !important;
}

.manifesto-header-alt {
  margin-bottom: 0 !important;
}

.breaking-img-width-alt {
  top: 45%;
  width: 35rem;
}

.support-text {
  font-weight: 400;
  font-size: 24px;
  /* margin-top: 1rem; */
  /* margin-bottom: 2rem; */
  line-height: 30px;
  text-wrap: wrap;
}

.z-action-10 {
  z-index: 100;
}

.the-flag-margin {
  margin: 5% 20%;
}

.bio-2-height {
  height: auto;
}

.take-action-pos {
  margin: 10rem 0rem;
}

.button-primary-mobile-d {
  padding: 5px 25px !important;
  box-shadow: 0px 13px 20px 0px rgb(15 15 15 / 78%);
}

/* .black-box {
  background-color: #000;
  width: 10rem;
  height: 10rem;
  position: absolute;
  top: 5%;
  left: 10%;
} */

/* .green-box {
  background-color: #009844;
  width: 15rem;
  height: 15rem;
  position: absolute;
  bottom: 15%;
  left: 8%;
} */

.red-box {
  background-color: #ec1c24;
  width: 15rem;
  height: 15rem;
  position: absolute;
  top: 20%;
  right: 8%;
}

.gold-box {
  background-color: #b5a232;
  width: 20rem;
  height: 20rem;
  position: absolute;
  bottom: 15%;
  right: 2%;
}

.products {
  padding: 3rem 0rem;
  width: 100%;
  height: 100%;
  background-image: url("./assets/G4.png");
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
}
.product {
  background-color: #111111;
  padding: 20px;
  color: #fff;
  margin-bottom: 20px;
  width: 40%;
  /* height:400px; */
}
.fadein {
  opacity: 0;
  transition: all 1.5s;
  transform: translate(0, 100px);
}

.fadein.visible {
  opacity: 1;
  transform: translate(0, 0);
}

/* Extra Small devices (phones, 0px and up) */
@media (max-width: 768px) {
  .take-banner-alt {
    position: absolute;
    top: 30%;
    left: 12%;
  }
  .nav-bg {
    position: fixed;
    background-color: #fff;
  }
  .header-text-sub {
    color: #b91f26;
    font-size: 22px;
    width: 10rem;
    font-weight: 600;
    /* width: 100%; */
    text-align: justify;
    line-height: 20px;
  }
  /* Your styles for extra small devices */
  .the-idea-text {
    color: #fff;
    font-weight: 900;
    font-size: 14px;
  }
  .button-green-primary {
    margin-right: 0px;
    margin-left: 0px;
    padding: 15px 15px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #009844;
    width: -moz-fit-content;
    width: fit-content;
    color: #fff;
    font-size: 22px;
    line-height: 10px;
    font-weight: 600;
    text-transform: none;
    z-index: 9;
  }
  .the-flag-margin {
    margin: 5% 12%;
  }
  .breaking-img-width {
    width: 13rem;
  }
  .breaking-position {
    right: 0;
  }
  .manifesto-p {
    font-weight: 400;
    font-size: 14px;
    color: #fff;
  }
  .manifesto-p-alt {
    color: #000;
  }

  .policy-header {
    font-size: 2.3rem;
    color: #fff;
    position: absolute;
    font-weight: 600;
    bottom: 2rem;
    left: 2rem;
  }

  .support-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-wrap: wrap;
  }

  .header-text-sub-alt-size {
    font-size: 1.6rem !important;
  }

  .hero-width {
    width: 85%;
    z-index: 1;
  }

  .manifesto-header {
    font-weight: 900;
    font-size: 23px;
    margin-top: 2rem;
    color: #b71d24;
    margin-bottom: 0rem;
    line-height: 28px;
    text-wrap: wrap;
  }

  .manifesto-header-sub {
    width: 12rem;
    font-size: 25px;
    margin-top: 10px;
    line-height: 18px;
  }

  .policy-header-sub {
    font-size: 1.4rem;
    color: #fff;
    position: absolute;
    font-weight: 600;
    bottom: 0.5rem;
    left: 5rem;
  }
  .support-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-wrap: wrap;
  }
  .text-position {
    position: absolute;
    top: 15rem;
    width: 92%;
    left: 1rem;
    z-index: 9;
  }

  .socials-position {
    position: absolute;
    left: 2rem;
    bottom: 1rem;
  }

  .red-nav-bg {
    height: 5rem;
  }

  .bio-p {
    padding: 1rem;
    width: 100%;
    margin-top: 0;
  }

  .take-action-p {
    font-size: 25px;
    line-height: 20px;
    margin-left: 0.5rem;
  }

  .bio-header {
    margin-left: 1rem;
  }

  .bio-header-sub {
    margin-left: 1rem;
    font-size: 24px;
    color: #009844;
    font-weight: 600;
    line-height: 28px;
  }
  .take-p {
    font-weight: 100;
    font-size: 16px;
    text-align: end;
    width: 11rem;
  }
  .breaking-img-width-alt {
    top: 50%;
    width: 10rem;
  }
}

/* Small devices (tablets, 576px and up) */
@media (min-width: 10px) and (max-width: 576px) {
  /* Your styles for small devices */
  .text-position {
    position: absolute;
    top: 12rem;
    width: 40%;
    left: 2rem;
    z-index: 9;
  }

  .home-banner-user {
    position: absolute;
    bottom: 0;
    left: 15rem;
  }
}

/* Medium devices (desktops, 768px and up) */
@media (min-width: 768px) and (max-width: 992px) {
  /* Your styles for medium devices */
  .text-position {
    position: absolute;
    top: 25rem;
    width: 40%;
    left: 2rem;
    z-index: 9;
  }

  .manifesto-header {
    font-weight: 900;
    font-size: 35px;
    margin-top: 2rem;
    color: #b71d24;
    margin-bottom: 2rem;
    line-height: 46px;
    text-wrap: wrap;
  }

  .header-text-sub-alt-size {
    font-size: 1.8rem !important;
  }

  .take-action-p {
    margin-top: 1rem;
    font-weight: 900;
    font-size: 35px;
    color: #b91f26;
  }

  .take-banner-alt {
    position: absolute;
    top: 20%;
    left: 25%;
  }

  .hero-width {
    width: 41%;
  }
  .text-position {
    position: absolute;
    top: 10rem;
    width: 40%;
    left: 2rem;
    z-index: 9;
  }

  .home-banner-user {
    position: absolute;
    bottom: 0;
    left: 15rem;
  }

  .custom-user-height {
    height: 95vh;
  }

  .socials-position {
    position: absolute;
    left: 3rem;
    bottom: 2rem;
  }

  .bio-img-1 {
    width: 100%;
    height: 85%;
  }
}

/* Large devices (large desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1200px) {
  /* Your styles for large devices */
}

/* Extra large devices (extra large desktops, 1200px and up) */
@media (min-width: 1570px) {
  /* Your styles for extra large devices */

  .header-text {
    font-size: 5rem;
    font-weight: 600;
  }
  .page-text {
    font-size: 55px;
    color: #fff;
    font-weight: 600;
  }
  .button-green-primary {
    margin-right: 0px;
    margin-left: 0px;
    padding: 25px 40px;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    background-color: #009844;
    width: -moz-fit-content;
    width: fit-content;
    color: #fff;
    font-size: 35px;
    line-height: 10px;
    font-weight: 600;
    text-transform: none;
    z-index: 9;
  }
  .manifesto-p {
    font-weight: 400;
    font-size: 40px;
  }
  .bio-p {
    margin-top: 2rem;
    color: #fff;

    font-size: 20px;
    text-align: justify;
    width: 90%;
    font-weight: 100;
  }

  .manifesto-header-small {
    font-size: 40px !important;
  }
  .button-primary {
    width: -moz-fit-content;
    width: fit-content;
    margin-right: 0px;
    margin-left: 0px;
    padding: 25px 50px;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    background-color: #b91f26;
    color: #fff;
    font-size: 35px;
    line-height: 17.41px;
    font-weight: 900;
    text-transform: none;
    z-index: 9;
  }
  .support-text {
    font-weight: 400;
    font-size: 40px;
    line-height: 45px;
    text-wrap: wrap;
  }
  .header-text-sub {
    color: #b91f26;
    font-size: 45px;
    font-weight: 600;
    width: 100%;
    text-align: justify;
    line-height: 45px;
  }
  .home-p {
    margin-top: 2rem;
    font-weight: 100;
    font-size: 30px;
  }
  .text-position {
    position: absolute;
    top: 9rem;
    width: 40%;
    left: 10rem;
    z-index: 9;
  }
  .lg\:pl-24 {
    padding-left: 25rem;
  }
  .take-banner-alt {
    position: absolute;
    top: 8%;
    left: 35%;
  }
  .custom-user-height {
    height: 125vh;
  }
  .home-banner {
    height: 700px;
  }
  .breaking-width {
    height: 110vh;
    width: 100%;
  }

  .breaking-position {
    position: absolute;
    top: 60%;
    left: 50%;
  }
  .breaking-img-width-alt {
    position: absolute;
    top: 70%;
    width: 45rem;
    left: 60%;
  }
  .breaking-img-width {
    width: 60rem;
  }
  .policy-header {
    font-size: 15rem;
    color: #fff;
    position: absolute;
    font-weight: 600;
    bottom: 5rem;
    left: 5rem;
  }
  .policy-header-sub {
    font-size: 5rem;
    color: #fff;
    position: absolute;
    font-weight: 600;
    bottom: 2rem;
    left: 16rem;
  }
}

.portrait-warning {
  display: none;
}


.scrollable-div {
  height: 30rem;
  overflow-x: hidden;
  overflow-y: auto;
  white-space: normal;
  position: relative;
}
/* 
.text-wrap{
  text-wrap: wrap
} */

.scrollable-div::-webkit-scrollbar {
  width: 10px;
  padding-left: 5rem;
  background-color: #fff;
}

.scrollable-div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #fff;
  border-radius: 10px;
  width: 30rem !important;
  background-color: #fff;
  border-left: 3px solid white;
  border-right: 3px solid white;
}

.scrollable-div::-webkit-scrollbar-thumb {
  border-radius: 20px;
  -webkit-box-shadow: inset 0 0 px #b71d24;
  background-color: #b71d24;
  padding-top: 20px;
}

.scrollable-div::-webkit-scrollbar-thumb:hover {
  background-color: #b71d24;
}

.manifesto-header-sub {
  color: #fff !important;
  width: 12rem !important;
}

.support-text-alt {
  color: #fff !important;
}

.scrollable-div::-webkit-scrollbar:vertical {
  display: block;
}

.accordion-border {
  border: solid 2px gray;
  padding: 1rem;
  border-radius: 5px;
}

.accordion-body-bg {
  background: #ececec;
  padding: 1rem;
  border-radius: 10px;
  margin-top: 10px;
}
